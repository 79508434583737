import React from 'react';
import { GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

import HomePage from './components/HomePage';
import { BonusProvider } from './context/BonusContext';
import { RequestHistoryProvider } from './context/RequestHistoryContext';

const App = () => {


  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LeYMw0qAAAAAPxOvCyJXad2D75aScLa3ZEvO8lq"
      scriptProps={{
        appendTo: "head",
      }}
    >
        <BonusProvider>
          <RequestHistoryProvider>
          <HomePage></HomePage>
          </RequestHistoryProvider>
        </BonusProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
