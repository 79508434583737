import React from 'react';
import '../css/TabItem.css';

const TabItem = ({ content, isActive, onClick }) => {
  return (
    <div 
      className={`tab-item ${isActive ? 'active' : ''}`}
      onClick={onClick} // Tıklama ile ilgili fonksiyonu çağır
    >
      {content}
    </div>
  );
};

export default TabItem;
