import React, { useEffect } from 'react';
import RequestHistory from './RequestHistoryDialog';
import '../css/historyButton.css';
import { useRequestHistory } from '../context/RequestHistoryContext';

const RequestHistoryButton = () => {
  const { isOpen, openModal, closeModal } = useRequestHistory(); // Context'ten değerleri alıyoruz

  // Modal açıldığında body scroll'unu kapatıyoruz
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Modal açıkken scroll'u kapat
    } else {
      document.body.style.overflow = 'auto';   // Modal kapandığında scroll'u geri getir
    }

    // Cleanup function: Modal kapandığında tekrar scroll'u açarız
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]); // isOpen her değiştiğinde bu etkiyi uygula

  return (
    <>
      <div onClick={openModal} className="request-history-button">
        <div className="icon-container">
          <img src="./images/image6.png" alt="arrow down" className="icon-image" />
        </div>
        <span className="button-text">Talep Geçmişi</span>
      </div>

      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()} >
            <RequestHistory closeModal={closeModal} />
          </div>
        </div>
      )}
    </>
  );
};

export default RequestHistoryButton;
