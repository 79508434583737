import React from 'react';
import '../css/Header.css';


const Header = ({userName}) => {

  return (
    <div className="header-container">
      <h1 className="header-title">
        <span className="greeting-emoji">👋🏻</span> Hoşgeldin, <span className="user-name" style={{ marginLeft: 10 }}>
          {userName || 'Kullanıcı'} {/* Kullanıcı adı yoksa 'Kullanıcı' gösterilir */}
        </span>
      </h1>
      <p className="header-subtitle">
        Lütfen talep ettiğiniz bonusu seçiniz.
      </p>
    </div>
  );
};

export default Header;
