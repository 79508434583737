import React from 'react';
import TabItem from './TabItem';
import '../css/Tabs.css';
import { useBonus } from '../context/BonusContext';

const Tabs = ({ onTabChange, selectedTab }) => {
  const { tabs } = useBonus();

  // Eğer tabs verisi yoksa, loading durumunu kontrol et veya boş bir array döndür
  if (!tabs || tabs.length === 0) {
    return <div>Yükleniyor...</div>; // Ya da istersen bir spinner gösterebilirsin
  }

  return (
    <div className="tabs-container">
      {tabs.map((tab) => (
        <TabItem 
          key={tab} 
          content={tab} 
          isActive={selectedTab === tab} 
          onClick={() => onTabChange(tab)} // Tab tıklamasında seçimi gönder
        />
      ))}
    </div>
  );
};

export default Tabs;
