import React from 'react';
import doubleBonus from '../requesthistory/doublebonus.png';
import CloseIcon from '@mui/icons-material/Close';
import '../css/DoubleBonus.css';
import { useSnackbarWithApiRequest } from '../helper/apiHelper';

export default function DoubleBonus({ closeModal, bonusData }) {

  const { SnackbarAlert, sendBonusRequest } = useSnackbarWithApiRequest();

  const handleBonusClick = (bonusId) => {
    sendBonusRequest(bonusId); // API isteği burada yapılır, Snackbar merkezden gösterilir
  };

  return (
    <div className="double-bonus">
      <div className="DoubleBonusheader">
        <span>Lütfen Bonus Şeç</span>
        <CloseIcon
          
          onClick={closeModal} 
          style={{ cursor: 'pointer' }}
          className='DoubleBonusCloseIcon' // Beyaz renk ayarladık
        />
      </div>

      <div className="empty-state1">
        <div className="modal-body">
          <img src={doubleBonus} alt="bonus" className="bonus-icon1" />
          <p>
            {bonusData.length > 2 ? (
              <>
                Bu bonus çok seçeneklidir lütfen <br />
                <span className="empty-subtext1">almak istediğiniz bonusu seçiniz!</span>
              </>
            ) : (
              <>
                Bu bonus çift seçeneklidir lütfen <br />
                <span className="empty-subtext1">almak istediğiniz bonusu seçiniz!</span>
              </>
            )}
          </p>
          <div className="buttons">
            <button className="button-primary" onClick={() => handleBonusClick(bonusData[0].bonus_id)}>
              {bonusData[0].bonus_name}
            </button>
            <span className="separator">veya</span>
            <button className="button-secondary" onClick={() => handleBonusClick(bonusData[1].bonus_id)}>
              {bonusData[1].bonus_name}
            </button>

            {/* 3. bonus varsa göster */}
            {bonusData.length > 2 && (
              <>
                <span className="separator">veya</span>
                <button className="button-primary" onClick={() => handleBonusClick(bonusData[2].bonus_id)}>
                  {bonusData[2].bonus_name}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {SnackbarAlert}
    </div>
  );
}
