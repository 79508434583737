const config = {
    development: {
      apiBaseUrl: 'https://orisbetbonus.xyz/api/',
      appName: 'OrisBetBonus',
      jsonUrl: 'https://orisbetbonus.xyz/panel-data.json',
    },
    production: {
      apiBaseUrl: 'api/',
      appName: 'OrisBetBonus',
      jsonUrl: '/panel-data.json'
    },
  };
  
  const env = 'production';
  export default config[env];
  